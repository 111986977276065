import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { BACKEND_URL } from "../config";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ThreeDotsMenu from './ThreeDotsMenu';

import { useMessageService } from '../services/MessageService';
import { useAuthUser } from '../contexts/AuthUserContext';
import { generateSharingCode, loadMonitoringAndAssessments } from '../utils/ObjectsUtils';
import { buttonStyle } from './styledComponents';
import { OptionTypes, UserType } from '../utils/enums';

const MonitoringsTable = ({ assessments, setAssessments, setCurrentMonitoringId, monitorings, setMonitorings }) => {

    const [editingCell, setEditingCell] = useState(null);
    const [editingCellValue, setEditingCellValue] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [newMonitoringName, setNewMonitoringName] = useState('');
    const [newMonitoringDescription, setNewMonitoringDescription] = useState('');
    const [error, setError] = useState(null);
    const [openLoadCodeDialog, setOpenLoadCodeDialog] = useState(false);
    const [loadCode, setLoadCode] = useState('');
    const [sortModel, setSortModel] = useState([{field: 'id', sort: 'desc',},]);

    const { getMessage } = useMessageService();
    const { currentUser } = useAuthUser();
    const [loadCodeError, setLoadCodeError] = useState('');

    useEffect(() => {
      console.log('Monitorings in table updated:', monitorings);
    }, [monitorings]);
    
    const handleClickAddNewMonitoring = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
  
    /**
     * Handles the addition of a new monitoring by creating a new monitoring object, adding it to the monitorings state, 
     * sending a POST request to the server to save the new monitoring, and updating the current monitoring ID.
     * 
     * @returns {Promise<void>} A promise that resolves once the new monitoring is added and saved to the server.
    */
    const handleAddMonitoring = async () => {

      let errorMessage = '';
        setError(null);

      // Check for empty mandatory fields
        if (!newMonitoringName || !newMonitoringDescription) {
            errorMessage = getMessage('new_monitoring_error_creation');
        }
        // Check for duplicate assessment on the same day
        else if (monitorings.some(monitoring => monitoring.name === newMonitoringName)) {
            errorMessage = `${getMessage('new_monitoring_error_duplicate')}.`;
        }
        // If there's an error, set the error message and abort the operation
        if (errorMessage) {
            setError(errorMessage);
            return;
        }

      console.log("Add new monitoring");

      try {
        // Retrieve token and decoded token
        const token = localStorage.getItem("token");
        // const decodedToken = jwt_decode(token);
    
        // Create new monitoring object
        const newMonitoring = {
            orderId: monitorings.length + 1,
            userId: currentUser._id,
            name: newMonitoringName,
            description: newMonitoringDescription,
            creationDate: new Date(),
            lastModification: new Date(),
            options: [OptionTypes.DELETE, OptionTypes.DELETE_ALL_ANSWERS]
        };

        // Send request to server to create the new monitoring
        const response = await axios.post(`${BACKEND_URL}/monitoring`, newMonitoring, {
            headers: {
              Authorization: `Bearer ${token}`
            }
        });

        // Set current monitoring server id
        const serverMonitoringId = response.data._id;
        newMonitoring._id = serverMonitoringId;
    
        // save the current monitoring ID
        setCurrentMonitoringId(serverMonitoringId);
        // Add new monitoring to rows
        setMonitorings(prevMonitorings => [...prevMonitorings, newMonitoring]);
    
        // Reset input fields and close dialog
        setNewMonitoringName('');
        setNewMonitoringDescription('');
        handleClose();
      } catch (error) {
        console.error("Error adding monitoring:", error);
      }
    };
  
    /**
     * Handles saving changes to a monitoring with the specified ID by updating the specified field.
     * 
     * @param {string} monitoringId - The unique identifier of the monitoring to be updated.
     * @param {string} field - The field to be updated in the monitoring.
     * @returns {Promise<void>} A promise that resolves once the changes are saved to the state and server-side data.
     */
    const handleUpdateMonitoring = async (monitoringId, field) => {

      console.log(`Updating the field ${field} of monitoring`, monitoringId);
    
      // Find the row by id
      const updatedMonitorings = monitorings.map((row) => {
        if (row._id === monitoringId) {
          return { ...row, [field]: editingCellValue };
        }
        return row;
      });
    
      
      // Update the server-side data
      try {
        const rowToUpdate = updatedMonitorings.find(row => row._id === monitoringId);
        const token = localStorage.getItem("token");
        await axios.put(`${BACKEND_URL}/updateEdited/monitorings/${monitoringId}`, rowToUpdate, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // save the monitoring state
        setMonitorings(updatedMonitorings);

      } catch (err) {
        console.error(err);
      }
    
      // Clear the editing state
      setEditingCell(null);
      setEditingCellValue('');
    };
  
    /**
     * Handles the deletion of a  monitoring 
     * sending a DELETE request to the server to delete the monitoring.
     * 
     * @returns {Promise<void>} A promise that resolves once the monitoring is deleted from the server.
     */
    const handleDeleteMonitoring = async (monitoringId) => {

      // TODO --- reassign the orderID ---
      console.log("deleting the monitoring", monitoringId);

      // DELETE the monitoring on the server as well as all the belonging assessments
      try {
        const token = localStorage.getItem("token");
        await axios.delete(`${BACKEND_URL}/monitoring/${monitoringId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log("Monitoring deleted successfully");
      } catch (err) {
        console.error(err);
      }
    
      // remove the deleted monitoring from the monitoring array
      const filteredMonitorings = monitorings.filter((monitoring) => monitoring._id !== monitoringId);
      
      // Re-assign IDs
      const newMonitorings = filteredMonitorings.map((monitoring, index) => ({
        ...monitoring,
        orderId: index + 1,
      }));
    
      // save the state
      setMonitorings(newMonitorings);
    
      // Filter out the assessments to be deleted
      const filteredAssessments = assessments.filter((assessment) => assessment.monitoringId !== monitoringId);
      setAssessments(filteredAssessments);
    };


    /**
     * Handles the deletion of the answers from a monitoring 
     * sending a DELETE request to the server to delete all answers associated to a given monitoring.
     * 
     * @returns {Promise<void>} A promise that resolves once the answers from a monitoring are deleted from the server.
     */
    const handleDeleteAnswers = async (monitoringId) => {

      console.log("delete all answers from monitoring", monitoringId);

      // DELETE all answers associated with this monitoring for the currend userId
      try {
        const token = localStorage.getItem("token");
        await axios.delete(`${BACKEND_URL}/monitoring/${monitoringId}/answers/${currentUser._id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log("Every answers from this monitoring deleted successfully");
      } catch (err) {
        console.error(err);
      }
    }
  
    /** 
    * Generates and assigns a unique sharing code to a specific monitoring based on its ID. 
    * The function updates the monitoring both locally and on the server with the new sharing code.
    * 
    * @param {string} monitoringId
    * 
    */
    const handleShareMonitoring = async (monitoringId) => {

      console.log(`Add a sharing code to the monitoring Id`, monitoringId);

      let sharingCode = generateSharingCode();

      // Find the row by id
      const updatedMonitorings = monitorings.map((row) => {
        if (row._id === monitoringId) {
          return { ...row, ["sharingCode"]: sharingCode };
        }
        return row;
      });

      // Update the server-side data
      try {
        const rowToUpdate = updatedMonitorings.find(row => row._id === monitoringId);
        const token = localStorage.getItem("token");
        await axios.put(`${BACKEND_URL}/updateEdited/monitorings/${monitoringId}`, rowToUpdate, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // save the monitoring state
        setMonitorings(updatedMonitorings);

      } catch (err) {
        console.error(err);
      }
    }

    /**
     * Load a sharingCode: if the code is valid, it will inport the corresponding monitoring and linked assessments
     */
    const handleLoadCode = async () => {
      const token = localStorage.getItem("token");
  
      if (!token) {
        alert("No authentication token available.");
        return;
      }
  
      try {
        const response = await axios.put(`${BACKEND_URL}/users/${currentUser._id}/append-code/${loadCode}`, {}, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200) {
            console.log("Code loaded successfully, and monitorings/assessments updated");
            setLoadCodeError(''); // Clear previous errors
            handleCloseLoadCodeDialog(); // Close dialog if success

            // load the new monitorings and associated assessments
            await loadMonitoringAndAssessments(currentUser, setMonitorings, setAssessments, setCurrentMonitoringId);
        }
      } catch (err) {
        console.error("Error while appending code:", err);
        if (err.response && err.response.status === 409) {
          setLoadCodeError("label_code_already_redeemed");
        } 
        else if (err.response && err.response.status === 404){
          setLoadCodeError("label_code_does_not_exist");
        }
        else {
          alert("An error occurred while loading the code.");
        }
      }
    };

    // Function for rendering the name cell
    const renderNameCell = (params) => (

      // we can edit the monitoring only if the user owns it
      currentUser && monitorings.some(row => row._id === params.row._id && row.userId === currentUser._id) && editingCell?.id === params.id && editingCell?.field === 'name' ? (
        <ClickAwayListener onClickAway={() => handleUpdate('name', params)}>
          <TextField 
            defaultValue={params.value} 
            onChange={(e) => {
              // Replace newline characters with an empty string
              const cleanedValue = e.target.value.replace(/\n/g, "");
              setEditingCellValue(cleanedValue);
            }}
            autoFocus
            onKeyDown={(e) => {
              e.stopPropagation()
              if (e.key === 'Enter'){
                e.preventDefault(); // Prevent the default behavior of Enter key
                handleUpdate('name', params)}
              }
            }
          />
        </ClickAwayListener>
      ) : (
        <div onClick={() => startEditing('name', params)}>{params.value}</div>
      )
    );


    // Function for rendering the description cell
    const renderDescriptionCell = (params) => (

      // we can edit the monitoring only if the user owns it
      currentUser && monitorings.some(row => row._id === params.row._id && row.userId === currentUser._id) && editingCell?.id === params.id && editingCell?.field === 'description' ? (
        <ClickAwayListener onClickAway={() => handleUpdate('description', params)}>
          <TextField 
            defaultValue={params.value}
            onChange={(e) => {
              // Replace newline characters with an empty string
              const cleanedValue = e.target.value.replace(/\n/g, "");
              setEditingCellValue(cleanedValue);
            }}
            autoFocus
            fullWidth
            onKeyDown={(e) => {
              e.stopPropagation()
              if (e.key === 'Enter'){
                e.preventDefault(); // Prevent the default behavior of Enter key
                handleUpdate('description', params)}
              }
            }
          />
        </ClickAwayListener>
      ) : (
        <div onClick={() => startEditing('description', params)}>{params.value}</div>
      )
    );

    // column definition
    const columns = [
      { field: 'name', headerName: getMessage('label_name'), width: 300, renderCell: renderNameCell },
      { field: 'description', headerName: getMessage('table_monitorings_description'), flex: 1, renderCell: renderDescriptionCell },
      // the option to share the monitoring is only available for teacher-trainers
      ...(currentUser.userStatus === UserType.TEACHER_TRAINER ? [{
        field: 'sharingCode',
        headerName: getMessage('label_sharing_status'),
        flex: 1,
        renderCell: (params) => (
          params.row.sharingCode
            ? params.row.sharingCode 
            : <Button
                variant="contained"
                color="primary"
                onClick={() => handleShareMonitoring(params.row._id)}
                sx={{ color: "black", backgroundColor: "#F7941E", borderRadius: "50px", "&:hover": { backgroundColor: "#D17A1D" }}}
              >
                {getMessage('label_button_share_monitoring')}
              </Button>
        )
      }] : []),
      // for Teacher, if the monitoring is imported, we should show the sharingCode
      ...(currentUser.userStatus === UserType.TEACHER ? [{
        field: 'status',
        headerName: (
          <Tooltip title={getMessage('label_tooltip_source')}>
            <span>{getMessage('label_source')}</span>
          </Tooltip>
        ),
        flex: 1,
        renderCell: (params) => (
          params.row.sharingCode
            ? getMessage('label_source_imported')
            : getMessage('label_source_owned')
        )
      }] : []),

      { field: 'creationDate', headerName: getMessage('table_monitorings_creation_date'), type: 'date', width: 130 },
      { field: 'lastModification', headerName: getMessage('table_monitorings_last_modification'), type: 'date', width: 130 },
      { field: 'actions', 
        headerName: getMessage('table_monitorings_actions'), 
        sortable: false,
        width: 80, 
        renderCell: (params) => (
          <ThreeDotsMenu 
            options={params.row.options} 
            onDeleteAllAnswers={() => handleDeleteAnswers(params.row._id)}
            onDelete={() => handleDeleteMonitoring(params.row._id)} 
          />
        )
      },
    ];

    // Helper functions for editing logic
    const startEditing = (field, params) => {
      setEditingCell({ id: params.id, field });
      setEditingCellValue(params.value);
    };

    const handleUpdate = (field, params) => {
      handleUpdateMonitoring(params.row._id, field);
      setEditingCellValue('');
    };

    const handleOpenLoadCodeDialog = () => {
        setOpenLoadCodeDialog(true);
    };

    const handleCloseLoadCodeDialog = () => {
        setOpenLoadCodeDialog(false);
    };


    return (
      <Box sx={{ height: 300, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <DataGrid
          rows={monitorings}
          columns={columns}
          pageSize={3}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          getRowId={(row) => row._id}
          onRowClick={(params) => setCurrentMonitoringId(params.row._id)}
          sx={{ height: '90%', "& .MuiDataGrid-cell:focus-within": { outline: "none" } }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
            {currentUser && currentUser.userStatus === "Teacher" && (
                <Button onClick={handleOpenLoadCodeDialog} variant="contained" startIcon={<AddIcon />} sx={buttonStyle}>
                    {getMessage('label_button_import_monitoring')}
                </Button>
            )}

          <Button onClick={handleClickAddNewMonitoring} variant="contained" startIcon={<AddIcon />} sx={buttonStyle}>
            {getMessage('table_monitoring_button_new_monitoring')}
          </Button>
        </Box>

        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle variant="h3">{getMessage('new_monitoring_create_new_monitoring')}</DialogTitle>
          <DialogContent>
            <TextField autoFocus size="small" margin="dense" id="name" label={getMessage('label_name')} type="text" fullWidth value={newMonitoringName} onChange={(e) => setNewMonitoringName(e.target.value)} />
            <TextField margin="dense" id="description" label={getMessage('new_monitoring_description')} type="text" fullWidth value={newMonitoringDescription} onChange={(e) => setNewMonitoringDescription(e.target.value)} />
          
            {error && 
                  <Box color="red" mt="15px">
                    <Typography>{error}</Typography>
                  </Box>
            }
          
          </DialogContent>
        
          <DialogActions>
              <Button onClick={handleClose}>{getMessage('label_cancel')}</Button>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <Button
                    onClick={handleAddMonitoring}
                    variant="contained"
                    color="primary"
                    sx={buttonStyle}
                >
                {getMessage('new_monitoring_create')}
                </Button>
                </Box>
          </DialogActions>
        </Dialog>

        <Dialog open={openLoadCodeDialog} onClose={handleCloseLoadCodeDialog}>
            <DialogTitle>{getMessage('load_code_dialog_title')}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="loadCode"
                    label={getMessage('label_code_dialog_enter')}
                    type="text"
                    fullWidth
                    value={loadCode}
                    onChange={(e) => setLoadCode(e.target.value)}
                />
                {loadCodeError && <Typography color="error" style={{ marginTop: 8 }}>{loadCodeError}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseLoadCodeDialog}>{getMessage('label_cancel')}</Button>
                <Button onClick={handleLoadCode}>{getMessage('label_import')}</Button>
            </DialogActions>
        </Dialog>
      </Box>
  );
};

export default MonitoringsTable;