import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useMessageService } from '../services/MessageService';
import { buttonStyle } from './styledComponents';

const FormActions = ({ handleReset, handleSubmit, questionsExist }) => {

    const { getMessage } = useMessageService();

    // Return statement for JSX
    return (
        <Box mt={5} display="flex" justifyContent="space-between" m="20px">
            <Button
                type="button"
                variant="contained"
                onClick={handleReset}
                sx={buttonStyle}
            >
                <Typography variant="h5">{getMessage('label_reset')}</Typography>
            </Button>
            {questionsExist && (
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    sx={buttonStyle}
                >
                    <Typography variant="h5">{getMessage('label_validate')}</Typography>
                </Button>
            )}
        </Box>
    );
};

export default FormActions;