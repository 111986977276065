import React from 'react';
import { Box } from '@mui/material';
import logo_dtc from "../assets/logo.svg";

const DTCLogo = () => {
  return (
    <Box mb="20px" display="flex" alignItems="center" justifyContent="center">
      <Box>
        <img width="100%" height="100%" src={logo_dtc} style={{ cursor: "pointer", borderRadius: "0%" }} />
      </Box>
    </Box>
  );
};

export default DTCLogo;