import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthUser } from '../contexts/AuthUserContext';  // Import useAuthUser hook

const PrivateRoute = ({ children, authorizedEmails }) => {
  const { isAuthenticated, currentUser } = useAuthUser(); // Use the hook to get auth user context

  // First, check if the user is authenticated.
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  // Next, if a list of authorized emails is provided, check against it.
  if (authorizedEmails && currentUser && !authorizedEmails.includes(currentUser.email)) {
    return <Navigate to="/unauthorized" />; // Redirect to an "unauthorized" page if not authorized
  }

  return children;
};

export default PrivateRoute;