import React, { useState } from 'react';
import { Typography, Box, IconButton, Avatar, Popover, Button } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";

import { useMessageService } from '../../services/MessageService';
import LanguageSelector from '../../components/LanguageSelector';

const Topbar = ({ title, logo, boxShadow }) => {
  const navigate = useNavigate();
  const [openLogoutPopover, setOpenLogoutPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { getMessage } = useMessageService();

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const handleOpenLogoutPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenLogoutPopover(true);
  };

  const handleCloseLogoutPopover = () => {
    setOpenLogoutPopover(false);
  };

  let initials = "";
  let isLoggedIn = false;
  let token = localStorage.getItem('token');
  
  if (token) {
    const decodedPayload = JSON.parse(atob(token.split('.')[1]));  // Decoding payload
    if (decodedPayload.firstName && decodedPayload.lastName) {
      initials = `${decodedPayload.firstName.charAt(0).toUpperCase()}${decodedPayload.lastName.charAt(0).toUpperCase()}`;
      isLoggedIn = true;
    }
  }

  return (
    <Box display="flex" justifyContent="space-between" padding={2} sx={{ boxShadow }}>

      <Box display="flex" flexDirection="column" mt={1}>
          <img alt="" src={logo} style={{ cursor: "pointer", borderRadius: "1%", maxWidth: "300px", minWidth: "300px" }}/>
          <Typography variant="h2" fontWeight="bold">{title}</Typography>
      </Box>


      <Box display="flex" alignItems="center">
        {isLoggedIn ? (
          <>
            <IconButton onClick={handleOpenLogoutPopover}>
              <Avatar>{initials}</Avatar>
            </IconButton>
            <Popover
              open={openLogoutPopover}
              onClose={handleCloseLogoutPopover}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Box p={2}>
                <Button onClick={handleLogout} color="error">{getMessage('label_logout')}</Button>
              </Box>
            </Popover>
          </>
        ) : (
          <IconButton onClick={() => navigate('/signin')}>
            <PersonOutlinedIcon sx={{ mr: '8px' }} />
            <Typography>{getMessage('label_signup')}</Typography>
          </IconButton>
        )}
        <LanguageSelector />
      </Box>
    </Box>
  );
};

export default Topbar;
