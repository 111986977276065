import { Formik, Form, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import { Box, TextField, Button, Select, MenuItem, InputLabel, Typography, FormControl, IconButton, FormControlLabel, Switch, Chip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { studentCompetenceAreas,
         PERENCompetences,
         PERENActivities,
         digCompCompetences,
         digCompActivities } from "../assets/frameworksData"
import { BACKEND_URL } from "../config";
import { useLanguage } from '../contexts/LanguageContext';
import { buttonStyle } from '../components/styledComponents'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import { toolbarConfig } from '../components/styledComponents'
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { QuestionType } from '../utils/enums';
import { useMessageService } from '../services/MessageService';

// Function to adjust image alignment
const convertImages = (htmlText) => htmlText.replace(/<div style="text-align:none;"><img/g, '<div style="text-align:center;"><img');

const API_KEY = process.env.REACT_APP_OPENAI_KEY; // API Key for Chat-GPT API

const AddStudentLearningQuestions = ({ setQuestions, questions, workshops, splitWorkshops }) => {

  const [numberOfQuestions, setNumberOfQuestions] = useState(1);
  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); 
  const [helpWithAI, setHelpWithAI] = useState(false);
  const [automaticEncoding, setAutomaticEncoding] = useState(false);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const {languageCode } = useLanguage();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { getMessage } = useMessageService();


  const languageMapping = {
    en: "English",
    de: "Deutsch",
    it: "Italian",
    fr: "Français",
  };

  const initialValues = { 
    question: "", 
    shortName: "", 
    context: "",
    questionType: "single-text",
    isMandatory: false,
    learningType: "", 
    correctAnswer: "",
    explanation: "",
    options: ["","","",""], 
    framework: "", 
    workshop: "", 
    area: "", 
    competency: "",
    proficiency: "", 
    activity: "",
    data: "",
    day: 0,
  };

  const FormResetWatcher = ({ splitWorkshops, initialValues }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!splitWorkshops) {
      // Reset only the category field
      setFieldValue('workshop', initialValues.workshop);
    }
  }, [splitWorkshops, setFieldValue, initialValues]);

  return null;
};

const addQuestionsSchema = (splitWorkshops, helpWithAI, questionType) => {
    
  let schema = {
      question: questionType === QuestionType.SINGLE_TEXT ? Yup.string().notRequired() : Yup.string().required("The question is required"),
      shortName: questionType === QuestionType.SINGLE_TEXT ? Yup.string().notRequired() : Yup.string().max(40, "The short name must be at most 40 characters long").required('The short name is required'),
      learningType: questionType === QuestionType.SINGLE_TEXT ? Yup.string().notRequired() : Yup.string().required("The learning type is required"),
      correctAnswer: questionType === QuestionType.RADIO_UNORDERED ? Yup.string().required("The correct answer is required") : Yup.string().notRequired(),
    };

    if (helpWithAI) {
      schema.question = Yup.string().notRequired();
      schema.shortName = Yup.string().notRequired();
      schema.learningType = Yup.string().required("The learning type is required");
      schema.correctAnswer = Yup.string().notRequired();

    };

    if (splitWorkshops) {
      schema.workshop = Yup.string().required("The workshop is required");
    } else {
      schema.workshop = Yup.string().notRequired();
    };

    return Yup.object().shape(schema);
  };


  // Event handler for changing the state of helpWithAI
  const handleHelpWithAIChange = (event) => {
    setHelpWithAI(event.target.checked);
    setAutomaticEncoding(event.target.checked);
    setSelectedCompetencies([]);

  };



  // Event handler for changing the state of automaticEncoding
  const handleAutomaticEncodingChange = (question, shortName, framework, event) => {
    
    // Update the automaticEncoding state based on the checkbox/switch state
    setAutomaticEncoding(event.target.checked);

    // Call updateCompetenciesForQuestion only if automaticEncoding is true
    if (event.target.checked) {
      updateCompetenciesForQuestion(question, shortName, framework);
    }
  };
    
  const handleSend = async (message, type) => {
      setResponse("");
      setIsLoading(false);
      setIsLoaded(false);
      setError(null); // Reset error message

      setIsLoading(true);

      try {
        const response = await processMessageToChatGPT(message, type);
        console.log("response: ", response)
        setResponse(response);
        setIsLoaded(true);
        setIsLoading(false);
        return response;
      } catch (error) {
        setError(error.message || "An unexpected error occurred");
        setIsLoading(false);      
        }
    };

    function getCompetencies(values) {

      switch (values.framework) {
        case "PER EN":
          return PERENCompetences[values.area] || [];
        case "DIGCOMP":
          return digCompCompetences[values.area] || [];
        default:
        return [];
      }
    }

    function getActivities(values) {
    if (values.framework === "DIGCOMP") {
      // Assuming digCompCompetences[competency] returns an object with keys 'Knowledge', 'Skill', 'Attitude'
      const activitiesByType = digCompActivities[values.competency];
      if (activitiesByType) {
        return activitiesByType[values.learningType] || [];
      }
    } else if (values.framework === "PER EN") {
      // Return PER EN activities if any
      return PERENActivities[values.competency] || [];
    }
    return [];
  }

const getAssessmentContent = (type) => {
  switch (type) { 
    case "Knowledge":
        return `Utilize the training material provided to formulate questions aimed at evaluating knowledge acquisition. The questions must follow a specific multiple-choice format with the correct answer, the question shortname and an explanation of the right answer. Follow this example strictly: \n
        1. [Insert Question Here]
        A) [Option A]
        B) [Option B]
        C) [Option C]
        D) [Option D]

        Correct Answer: A) [Insert Correct Option Here]
        ShortName: [Insert Question Shortname of Max 40 Characters Here With Spaces Between Words]
        Explanation: [Explanation of the right answer]`;

    case "Skill":
        return `Utilize the training material provided to formulate questions aimed at evaluating skill acquisition. The questions must follow a specific multiple-choice format with the correct answer, the question shortname and an explanation of the right answer. Follow this example strictly: \n       
        1. [Insert Question Here]
        A) [Option A]
        B) [Option B]
        C) [Option C]
        D) [Option D]

        Correct Answer: A) [Insert Correct Option Here]
        ShortName: [Insert Question Shortname of Max 40 Characters Here With Spaces Between Words]
        Explanation: [Explanation of the right answer]`;

    case "Attitude":
        return `Utilize the training material provided to formulate questions aimed at evaluating attitude acquisition. The questions must follow a specific multiple-choice format with the correct answer, the question shortname and an explanation of the right answer. Follow this example strictly: \n      
        1. [Insert Question Here]
        A) [Option A]
        B) [Option B]
        C) [Option C]
        D) [Option D]

        Correct Answer: A) [Insert Correct Option Here]
        ShortName: [Insert Question Shortname of Max 40 Characters Here With Spaces Between Words]
        Explanation: [Explanation of the right answer]`;

    default:
            return "";
    }
};

  async function processMessageToChatGPT(message, type) {
    const apiRequestBody = {
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: getAssessmentContent(type)
            
        },
        { role: "user", content: message },
      ],
      temperature: 0.1,
    };

    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",

      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + API_KEY,
          "Content-Type": "application/json",
        },

        body: JSON.stringify(apiRequestBody),
      }
    );

    const data = await response.json();
    return data.choices[0].message.content;
  }

  const findCompetencies = async (question, shortName, framework) => {

    try {
      if (framework) {
        const query = `${question} ${shortName}`;
        const response = await axios.post(`${BACKEND_URL}/query-embedding-faiss`, {
          query,
          collectionName: framework
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });
        return response.data;
      } else {
        console.log("Framework is not specified. Skipping the request.");
        return null;
      }
    } catch (error) {
      console.error('Error sending query to backend:', error);
    }
  };



const updateCompetenciesForQuestion = async (question, shortName, framework) => {

    // Fetch competencies
    const competencies = await findCompetencies(question, shortName, framework);
    console.log("competencies: ", competencies);

    // Assume competencies is an array of competency strings
    if (competencies && competencies.length > 0) {
      // Convert fetched competencies to the desired format
      const competenciesWithFramework = competencies.map(competency => `${framework} ${competency}`);
      // Update state
      setSelectedCompetencies(competenciesWithFramework);
      return competenciesWithFramework; // Return the competencies for further use
    } else {
      // Handle the case where no competencies are found or an error occurred
      setSelectedCompetencies([]);
      return [];
    }
};

const handleCompetencyChange = (event) => {
  setSelectedCompetencies([...selectedCompetencies, event.target.value]);
};

const handleDeleteCompetency = (competencyToRemove) => {
  setSelectedCompetencies(selectedCompetencies.filter(competency => competency !== competencyToRemove));
};

const handleSubmit = async (values, { resetForm }) => {

  // Convert editorState to HTML
  let editorContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  editorContent = convertImages(editorContent);
  let newQuestionId = questions.reduce((maxId, question) => Math.max(maxId, parseInt(question.questionId, 10)), 0) + 1;

  if (helpWithAI) {
    let content = `Make sure that the questions are aligned with the following information.\n
    Number of questions: ${numberOfQuestions}.\n
    Description of the activity: ${values.data}.\n
    Output language: ${languageMapping[languageCode]}`;

    try {
      const response = await handleSend(content, values.learningType);

      const questionsRegex = /(\d+\..+?)(?=\n\d+\.|$)/gs;
      const matches = [...response.matchAll(questionsRegex)];

      let currentQuestionId = newQuestionId;
      let parsedQuestions = []; // Initialize an empty array to hold the parsed questions

      for (const match of matches) {
        const lines = match[0].trim().split('\n');
        const questionText = lines[0].replace(/^\d+\.\s*/, '');
        const options = lines.filter(line => /^[A-Z]\)/.test(line)).map(line => line.split(') ')[1]);
        const regex = /([A-Z])\)/; 
        const regexMatch = lines[6].match(regex);
        const correctLetter = regexMatch ? regexMatch[1] : null;
        let correctAnswer = null;
        if (correctLetter) {
          const answerRegex = new RegExp(`^${correctLetter}\\)`);
          const answerLine = lines.find(line => answerRegex.test(line));
          correctAnswer = answerLine ? answerLine.split(') ')[1] : null;
        }
        const shortNameRegex = /ShortName:\s*(.+)/;
        const shortNameMatch = match[0].match(shortNameRegex);
        const shortName = shortNameMatch ? shortNameMatch[1].trim() : '';
        const explanationRegex = /Explanation:\s*(.+)/;
        const explanationMatch = match[0].match(explanationRegex);
        const explanation = explanationMatch ? explanationMatch[1].trim() : '';

        // Fetch competencies for each question asynchronously
        const competencies = await updateCompetenciesForQuestion(questionText, shortName, values.framework);

        // Construct the question object with its competencies
        const question = {
          questionId: currentQuestionId.toString(),
          question: questionText,
          shortName: shortName,
          questionType: 'radio-unordered',
          isMandatory: values.isMandatory,
          learningType: values.learningType,
          workshop: values.workshop,
          correctAnswer: correctAnswer,
          explanation: explanation,
          framework: values.framework,
          options: options.map((option, optionIndex) => ({ value: String.fromCharCode(65 + optionIndex), label: option })),
          competencies // Directly use the fetched competencies
        };

        currentQuestionId++; // Increment the questionId for the next question

        parsedQuestions.push(question); // Add the question to the parsedQuestions array
      }

      setQuestions(prevQuestions => [...prevQuestions, ...parsedQuestions]);

    } catch (error) {
        console.error("Error:", error);
    }

  } else {

    const newQuestion = {
      questionId: newQuestionId.toString(),
      question: values.question,
      shortName: values.shortName,
      context: editorContent,
      correctAnswer: values.correctAnswer,
      explanation: values.explanation,
      questionType: values.questionType,
      learningType: values.learningType,
      workshop: values.workshop,
      framework: values.framework,
      options: values.questionType === "text" || values.questionType === "single-text"
          ? [] 
          : values.options.map((option, index) => ({ value: `${index + 1}`, label: option })),
      competencies: [...selectedCompetencies]
    };

    setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
    setSelectedCompetencies([]);
    setAutomaticEncoding(false);
  }

  resetForm({
    values: {
      ...values,
      question: '',
      shortName: '',
      context: '',
      workshop: '',
      correctAnswer: '',
      explanation: '',
      learningType: '',
      competencies: [],
    }
  });
};


  const styles = {
    formGroup: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "1rem",
    },
    invalidFeedback: {
      color: "red",
      marginTop: "0.25rem",
    },
  };


  return (
    <>
    <Box
      flexDirection="column"
      display="flex"
      sx={{
          backgroundColor: "#fff",
          marginBottom: "20px",
          paddingLeft: "20px",
      }}
      >

      <FormControlLabel
      control={<Switch checked={helpWithAI} onChange={handleHelpWithAIChange} />}
      label="Create questions with AI"
      /> 
    </Box>

    <Box ml="20px" mr="20px">
      <Box
        sx={{
          p: "10px",
          borderRadius: "4px",
        }}
      >

          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={Yup.lazy(values => addQuestionsSchema(splitWorkshops, helpWithAI, values.questionType))}
          >   
          {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <FormResetWatcher splitWorkshops={splitWorkshops} initialValues={initialValues} />
            {splitWorkshops && (
              
              <FormControl fullWidth>
                  <InputLabel id="selectedWorkshop">Choose the category</InputLabel>
                  <Select
                  labelId="selectedWorkshop"
                  id="selectedWorkshop"
                  name="workshop"
                  value={values.workshop}
                  label="Choose the category"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.workshop && errors.workshop ? true : false}
                  sx={{mb:"15px"}}
                >
                  {workshops.length > 0 && 
                    workshops.map((workshop, index) => (
                      <MenuItem key={index} value={workshop}>
                        {workshop}
                      </MenuItem>
                    ))
                  }
                </Select>
                </FormControl>
              )}

              {!helpWithAI ? (
              <>
                <FormControl fullWidth>
                  <InputLabel id="questionType">Question Type</InputLabel>
                  <Select
                    labelId="questionType"
                    id="questionType"
                    name="questionType"
                    value={values.questionType}
                    label="Question Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.questionType && errors.questionType ? true : false}
                    sx={{ mb: "15px" }}
                  >
                    <MenuItem value="single-text">Single Text</MenuItem>
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="radio-unordered">Multiple choices</MenuItem>
                  </Select>
                </FormControl>

                {values.questionType === "single-text" && (
                  <Box margin="normal">
                    <Box border={1} borderColor="grey.400" p={2} mb={2} borderRadius={1}>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={setEditorState}
                        placeholder="Enter contextual information"
                        toolbar={toolbarConfig}
                      />
                    </Box>
                  </Box>
                )}


                {values.questionType !== "single-text" && (
                  <>
                  <FormControl fullWidth>
                    <TextField
                      id="question"
                      label="Enter your question"
                      value={values.question}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.question && errors.question ? true : false}
                      sx={{mb:"15px"}}
                      helperText={touched.question && errors.question ? errors.question : ""}
                      />
                  </FormControl>

                  <FormControl fullWidth>
                  <TextField
                      id="shortName"
                      label="Enter a short name for your question"
                      name="shortName"
                      value={values.shortName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ mb: "15px" }}
                      error={touched.shortName && errors.shortName ? true : false}
                      helperText={touched.shortName && errors.shortName ? errors.shortName : ""}
                    />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="learningType">Learning Type</InputLabel>
                  <Select
                    labelId="learningType"
                    id="learningType"
                    name="learningType"
                    value={values.learningType}
                    label="Learning Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.learningType && errors.learningType ? true : false}
                    sx={{mb:"15px"}}
                  >
                    <MenuItem value="Knowledge">Knowledge</MenuItem>
                    <MenuItem value="Skill">Skill</MenuItem>
                    <MenuItem value="Skill">Attitude</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="selectedFramework">Choose a framework </InputLabel>
                  <Tooltip placement="top" title="Allows you to categorize your learning outcomes in connection with recognized frameworks">
                    <Select
                      labelId="selectedFramework"
                      id="selectedFramework"
                      name="framework"
                      value={values.framework}
                      label="Choose a framework"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.framework && errors.framework ? true : false}
                      sx={{ mb: "15px" }}
                    >
                      {Object.keys(studentCompetenceAreas).map((framework, index) => (
                        <MenuItem key={index} value={framework}>
                          {framework}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>

                <FormControlLabel
                control={<Switch 
                  checked={automaticEncoding} 
                  onChange={(event) => handleAutomaticEncodingChange(values.question, values.shortName, values.framework, event)}
                  disabled={helpWithAI} />}
                label="Automatic encoding of competencies"
                sx={{mb:"15px"}}/> 

                <Typography sx={{ mb: "15px" }}>Add competencies manually :</Typography>
                <FormControl fullWidth>
                  <InputLabel id="selectedArea">Choose a competency area </InputLabel>
                  <Select
                    labelId="selectedArea"
                    id="selectedArea"
                    name="area"
                    value={values.area}
                    label="Choose a competency area"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.area && errors.area ? true : false}
                    sx={{ mb: "15px" }}
                  >
                    {(studentCompetenceAreas[values.framework] || []).map((area, index) => (
                      <MenuItem key={index} value={area}>
                        {area}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="selectedCompetency">Choose an elementary competency </InputLabel>
                  <Select
                    labelId="selectedCompetency"
                    id="selectedCompetency"
                    name="competency"
                    value={values.competency}
                    label="Choose an elementary competency"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.competency && errors.competency ? true : false}
                    sx={{ mb: "15px" }}
                  >
                    {getCompetencies(values).map((competency, index) => (
                      <MenuItem key={index} value={competency}>
                        {competency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="selectedActivity">Choose an activity</InputLabel>
                  <Select
                    labelId="selectedActivity"
                    id="selectedActivity"
                    name="activity"
                    value={values.activity}
                    label="Choose an activity"
                    onChange={handleCompetencyChange}
                    onBlur={handleBlur}
                    error={touched.activity && errors.activity ? true : false}
                    sx={{ mb: "15px" }}
                  >
                    {getActivities(values).map((activity, index) => (
                      <MenuItem key={index} value={activity}>
                        {activity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box display="flex" flexWrap="wrap" sx={{ mb: "15px" }}>
                  {selectedCompetencies.map((competency, index) => (
                    <Chip
                      key={index}
                      label={competency}
                      onDelete={() => handleDeleteCompetency(competency)}
                      sx={{ mr: "5px", mb: "5px" }}
                    />
                  ))}
                </Box>

                </>
              )}

              {values.questionType !== "text" && values.questionType !== "single-text" && (
                <>
                  <h4>Answer Options</h4>
                  <FieldArray name="options">
                    {({ remove, push }) => (
                      <>
                        {values.options.map((option, index) => (
                          <div key={index} style={styles.formGroup}>
                            <div style={styles.inputGroup}>
                              <TextField
                                  id={`options.${index}`}
                                  name={`options.${index}`}
                                  placeholder={`Option ${index + 1}`}
                                  value={option}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.options &&
                                    touched.options[index] &&
                                    errors.options &&
                                    errors.options[index]
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    touched.options &&
                                    touched.options[index] &&
                                    errors.options &&
                                    errors.options[index]
                                      ? errors.options[index]
                                      : ''
                                  }
                                  sx={{ width: '80%' }}
                              />
                              <IconButton onClick={() => remove(index)}>
                                      <Delete />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                         

                         <Button onClick={() => push('')} variant="contained" sx={{...buttonStyle, mb: 5}}>
                            <Typography variant="h5">ADD OPTION</Typography>
                        </Button>

                        <FormControl fullWidth>
                          <InputLabel id="selectedCorrectAnswer">Choose the correct answer</InputLabel>
                          <Select
                            labelId="selectedCorrectAnswer"
                            id="selectedCorrectAnswer"
                            name="correctAnswer"
                            value={values.correctAnswer}
                            label="Choose the correct answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.correctAnswer && errors.correctAnswer ? true : false}
                            sx={{mb:"15px"}}
                          >
                            {values.options.length > 0 && 
                              values.options.map((answer, index) => 
                                  (answer !== "" && (
                                      <MenuItem key={index} value={answer}>
                                          {answer}
                                      </MenuItem>
                                  ))
                              )
                            }
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </FieldArray>
                
                <FormControl fullWidth>
                <TextField
                  id="explanation"
                  label="Give an explanation"
                  name="explanation"
                  value={values.explanation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline
                  rows={4}
                  sx={{ mb: "15px" }}
                  error={touched.explanation && errors.explanation ? true : false}
                  helperText={touched.explanation && errors.explanation ? errors.explanation : ""}
                />
                </FormControl> 
              </>
              )}
              </>

            ) : (

            helpWithAI && (
              <>
              <FormControl fullWidth sx={{ mb: "15px" }}>
                <TextField
                  id="selectedData"
                  label="Please provide a description or content of the activity (up to 3500 characters)"
                  name="data"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline
                  rows={10}
                  error={touched.selectedData && errors.selectedData ? true : false}
                  helperText={touched.selectedData && errors.selectedData ? errors.selectedData : ""}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="learningType">Learning Type</InputLabel>
                <Select
                  labelId="learningType"
                  id="learningType"
                  name="learningType"
                  value={values.learningType}
                  label="Learning Type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.learningType && errors.learningType ? true : false}
                  sx={{mb:"15px"}}
                >
                  <MenuItem value="Knowledge">Knowledge</MenuItem>
                  <MenuItem value="Skill">Skill</MenuItem>
                  <MenuItem value="Attitude">Attitude</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="selectedFramework">Choose a framework </InputLabel>
                <Tooltip placement="top" title="Allows you to categorize your learning outcomes in connection with recognized frameworks">
                  <Select
                    labelId="selectedFramework"
                    id="selectedFramework"
                    name="framework"
                    value={values.framework}
                    label="Choose a framework"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.framework && errors.framework ? true : false}
                    sx={{ mb: "15px" }}
                  >
                    {Object.keys(studentCompetenceAreas).map((framework, index) => (
                      <MenuItem key={index} value={framework}>
                        {framework}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </FormControl>

              <FormControlLabel
              control={<Switch 
                checked={automaticEncoding} 
                onChange={(event) => handleAutomaticEncodingChange(values.question, values.shortName, values.framework, event)}
                disabled={helpWithAI} />}
              label="Automatic encoding of competencies"
              sx={{mb:"15px"}}/> 

              <FormControl fullWidth>
                <Box display="flex" alignItems="center">
                  <Typography>{getMessage("label_choose_number_questions")} &nbsp; </Typography>
                  <TextField 
                  id="numberOfQuestions" 
                  type="number"
                  name="number"
                  autoFocus
                  size="small"
                  style={{ width: "70px" }}
                  margin="dense"
                  inputProps={{ min: "1" }}
                  value={values.numberOfQuestions}
                  onChange={(e) => {
                      const value = parseInt(e.target.value, 0);
                      if (value >= 1) {
                          setNumberOfQuestions(value);
                          
                        } else {
                            setNumberOfQuestions(1);
                        }
                    }} 
                  />
                </Box>
              </FormControl>
            </>
          ))}

            <Box sx={{mt:"30px"}}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#F7941E",
                  borderRadius: "50px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#D17A1D",
                  },
                }}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? <CircularProgress size={24} /> : <Typography variant="h5">ADD</Typography>}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  </Box>
  </>
)};

export default AddStudentLearningQuestions;
