import React from 'react';
import { Switch, FormControlLabel, Typography, Box, IconButton, Grid, TextField, Button, Chip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Delete } from "@mui/icons-material";

import { questionContainerStyle, formControlStyle } from './styledComponents';
import { changeLearningType, saveEdits, addOption, changeOption, removeOption, changeCorrectAnswer } from '../utils/SurveyUtils';
import { traineeCompetenceAreas } from "../assets/frameworksData";
import QuestionControlsView from './QuestionControlsView';
import ContextControlsView from './ContextControlsView';
import SurveyQuestion from './SurveyQuestion';
import { buttonStyle } from './styledComponents';

// for i18n
import { useMessageService } from '../services/MessageService';

// The view to edit a question
const EditQuestionView = ({ questions, question, setQuestions, editingQuestionId, setEditingQuestionId, 
                            handleCompetencyChange, automaticEncoding, handleAutomaticEncodingChange, selectedArea,
                            selectedCompetency, getCompetencies, activity, getActivities }) => {

    // for the translations
    const { getMessage } = useMessageService();

    // Handle changes to question properties
    const handleChange = (prop, value) => {
        setQuestions(prevQuestions =>
            prevQuestions.map(q => q.questionId === question.questionId ? { ...q, [prop]: value } : q)
        );
    };

    const handleDeleteCompetency = (questionId, competencyToDelete) => {
        setQuestions(questions.map(question => {
            if (question.questionId === questionId) {
                return {
                    ...question,
                    competencies: question.competencies.filter(competency => competency !== competencyToDelete),
                };
            }
            return question;
        }));
    };

    return (
        <Box component="div" sx={{ width: '100%', p: 2, margin: "20px", borderRadius: '8px', backgroundColor: 'rgb(245,245,245)', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Typography mb="20px" variant="h4" fontWeight="bold">
                {getMessage("label_edit_question")}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={getMessage("label_question_title")}
                        variant="outlined"
                        value={question.question}
                        InputProps={{style: { backgroundColor: 'white' }}}
                        onChange={e => handleChange('question', e.target.value)}/>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={getMessage("label_short_name")}
                        variant="outlined"
                        value={question.shortName}
                        InputProps={{style: { backgroundColor: 'white' }}}
                        onChange={e => handleChange('shortName', e.target.value)}/>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="learningType">
                            {getMessage("label_learning_type")}
                        </InputLabel>
                        <Select
                            labelId="learningType"
                            id="learningType"
                            value={question.adoptionType || ''}
                            label={getMessage("label_learning_type")}
                            sx={{ backgroundColor: 'white' }} // Set background color to white
                            onChange={(event) => changeLearningType(setQuestions, event, question.questionId)}>
                            <MenuItem value={'Knowledge'}>
                                {getMessage("label_knowledge")}
                            </MenuItem>
                            <MenuItem value={'Skill'}>
                                {getMessage("label_skill")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>


                {question.questionType !== 'text' && question.questionType !== 'single-text' && (

                    <>
                        <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px' }}> 
                            {question.options.map((option, index) => (
                                <Grid item xs={6} key={index}> 
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <TextField
                                            fullWidth
                                            label={`${getMessage("label_option")} ${index + 1}`}
                                            variant="outlined"
                                            InputProps={{style: { backgroundColor: 'white' }}}
                                            value={option.label}
                                            onChange={(e) => changeOption(setQuestions,e, question.questionId, index)}/>
                                        <IconButton onClick={() => removeOption(setQuestions, question.questionId, index)}>
                                        <Delete />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid> 


                        <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px', justifyContent: "center" }}>
                            <Grid item xs={6}>
                                <Button variant="outlined" sx={buttonStyle} onClick={() => addOption(setQuestions, question.questionId)} fullWidth>
                                    {getMessage("label_add_option")}  
                                </Button>
                            </Grid>
                        </Grid>


                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" sx={formControlStyle}>
                                <InputLabel id="selectedCorrectAnswer">
                                    {getMessage("label_choose_correct_answer")}  
                                </InputLabel>
                                <Select
                                    labelId="selectedCorrectAnswer"
                                    id="selectedCorrectAnswer"
                                    value={question.correctAnswer || ''}
                                    label={getMessage("label_choose_correct_answer")}  
                                    onChange={(event) => changeCorrectAnswer(setQuestions, event, question.questionId)}>
                                    {question.options.length > 0 && 
                                        question.options.map((option, index) => 
                                            (option.label !== "" && (
                                                <MenuItem key={index} value={option.label}>
                                                {option.label}
                                                </MenuItem>
                                            ))
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={getMessage("label_explanation")}  
                                variant="outlined"
                                multiline
                                rows={4}
                                InputProps={{style: { backgroundColor: 'white' }}}
                                value={question.explanation}
                                onChange={e => setQuestions(prevQuestions =>
                                    prevQuestions.map(q =>
                                        q.questionId === question.questionId ? { ...q, explanation: e.target.value } : q
                                    )
                                )}/>
                        </Grid>
                    </>

                )}

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <FormControlLabel
                            control={<Switch checked={automaticEncoding} onChange={handleAutomaticEncodingChange} />}
                            label={getMessage("label_automatically_recode_competencies")}  
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>

                    <Typography sx={{ mb: "15px" }}>
                        {getMessage("label_add_competencies_manually")}
                    </Typography>
                    
                    <FormControl fullWidth variant="outlined" sx={formControlStyle}>
                        <InputLabel id="selectedArea">
                            {getMessage("label_choose_competency_area")}
                        </InputLabel>
                        <Select
                            labelId="selectedArea"
                            id="selectedArea"
                            label={getMessage("label_choose_competency_area")}
                            value={selectedArea}
                            onChange={handleCompetencyChange}
                            name="area"
                            sx={{ mb: "15px" }}
                        >
                            {(traineeCompetenceAreas[questions.find(question => question.questionId === editingQuestionId)?.framework] || []).map((area, index) => (
                                <MenuItem key={index} value={area}>{area}</MenuItem>
                            ))}                                                                  
                        </Select>
                    </FormControl>

                    {selectedArea && (
                        <FormControl fullWidth variant="outlined" sx={formControlStyle}>
                            
                            <InputLabel id="selectedCompetency">
                                {getMessage("label_choose_competency")}
                            </InputLabel>
                            <Select
                                labelId="selectedCompetency"
                                id="selectedCompetency"
                                label={getMessage("label_choose_competency")}
                                value={selectedCompetency}
                                onChange={handleCompetencyChange}
                                name="competency"
                                sx={{ mb: "15px" }} 
                            >
                            {getCompetencies({ area: selectedArea, framework: questions.find(question => question.questionId === editingQuestionId)?.framework }).map((competency, index) => (
                                <MenuItem key={index} value={competency}>{competency}</MenuItem>
                            ))}                                                                    
                            </Select>
                        </FormControl>
                    )}

                    {selectedCompetency && (
                        <FormControl fullWidth variant="outlined" sx={formControlStyle}>
                            <InputLabel id="selectedActivity">
                                {getMessage("label_choose_activity")}
                            </InputLabel>
                            <Select 
                                labelId="selectedActivity" 
                                id="selectedActivity" 
                                label={getMessage("label_choose_activity")}
                                value={activity} 
                                onChange={handleCompetencyChange} 
                                name="activity">
                                {getActivities({ competency: selectedCompetency, framework: questions.find(question => question.questionId === editingQuestionId)?.framework }).map((activity, index) => (
                                    <MenuItem key={index} value={activity}>{activity}</MenuItem>  
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5, maxWidth: '100%', overflow: 'hidden'}}>
                        {question.competencies.map((competency, index) => (
                            <Chip key={index} label={competency} onDelete={() => handleDeleteCompetency(question.questionId, competency)}/>
                        ))}
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Button type="button" variant="contained" onClick={() => setEditingQuestionId(null)} sx={{backgroundColor: "#F7941E", borderRadius: "50px", color: "black", "&:hover": {backgroundColor: "#D17A1D",},}}>
                            <Typography variant="h5">
                                {getMessage("label_cancel")}
                            </Typography>
                        </Button>
                        
                        <Button type="button" variant="contained" onClick={() => saveEdits(setQuestions, setEditingQuestionId, question.questionId, question)} sx={{backgroundColor: "#F7941E", borderRadius: "50px", color: "black", "&:hover": { backgroundColor: "#D17A1D",},}}>
                            <Typography variant="h5">
                                {getMessage("label_validate")}
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const QuestionsListSectionLearning = ({
    workshopName,
    questions,
    setQuestions,
    setEditingQuestionId,
    editingQuestionId,
    setFieldValue,
    handleCompetencyChange,
    handleAutomaticEncodingChange,
    automaticEncoding,
    selectedArea,
    selectedCompetency,
    getActivities,
    getCompetencies,
    activity
     }) => (

        <div key={workshopName}>
            {workshopName !== "undefined" && 
                (<Typography variant="h4" fontWeight="bold" color="rgb(102,102,102)" m="15px 10px 0px 0px">
                    {workshopName}
                </Typography>)
            }
            
            {questions.map((question, index) => (

                <Box key={index} sx={questionContainerStyle} display="flex" alignItems="center">

                {editingQuestionId === question.questionId ? (

                    <EditQuestionView
                        questions={questions}
                        question={question}
                        setQuestions={setQuestions}
                        setEditingQuestionId={setEditingQuestionId}
                        handleCompetencyChange={handleCompetencyChange}
                        automaticEncoding={automaticEncoding}
                        handleAutomaticEncodingChange={handleAutomaticEncodingChange}
                        selectedArea={selectedArea}
                        editingQuestionId={editingQuestionId}
                        selectedCompetency={selectedCompetency}
                        getCompetencies={getCompetencies}
                        activity={activity}
                        getActivities={getActivities}
                        saveEdits={saveEdits}/>
                ) : (

                    <SurveyQuestion
                        question={question.question}
                        shortName={question.shortName}
                        context={question.context}
                        fieldName={question.fieldName}
                        options={question.options}
                        type={question.questionType}
                        correctAnswer={question.correctAnswer}
                        explanation={question.explanation}
                        competencies={question.competencies}
                        setFieldValue={setFieldValue}
                        titleFontSize="18px"
                        optionFontSize="14px"
                        disabled={true}
                    />
                )}

                    {question.questionType === 'single-text' ? (
                    <ContextControlsView
                        questions={questions}
                        setQuestions={setQuestions}
                        setEditingQuestionId={setEditingQuestionId}
                        question={question}
                    />
                ) : (
                    <QuestionControlsView
                        questions={questions}
                        setQuestions={setQuestions}
                        setEditingQuestionId={setEditingQuestionId}
                        question={question}
                    />
                )}
                </Box>
            ))}
        </div>
);

export default QuestionsListSectionLearning;
