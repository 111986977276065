import { Box, Typography, FormControlLabel, Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import React, { useState, useEffect } from "react";

import AddQuestion from "./AddQuestion";
import AddWorkshop from "./AddWorkshop";
import QuestionsListSection from './QuestionSection';
import FormActions from './FormActions';
import { saveSurveyToAssessment, fetchExistingSurvey } from '../utils/SurveyUtils';
import { useLanguage } from '../contexts/LanguageContext';

// for i18n
import { useMessageService } from '../services/MessageService';


const AddSurvey = ({ currentAssessmentServerId, predifinedQuestionIds }) => {
    
    const location = useLocation();
    const navigate = useNavigate();

    // States declaration
    const { assessmentType } = location.state || {};
    const [initialQuestions, setInitialQuestions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [splitWorkshops, setSplitWorkshops] = useState(false);
    const [editingQuestionId, setEditingQuestionId] = useState(null); 

    // for the translations
    const { getMessage } = useMessageService();
    const { languageCode } = useLanguage();

    useEffect(() => {

        console.log("---------- questions UPDATED ----------");
        console.log("questions", questions);
        
      }, [questions]);


    useEffect(() => {

        fetchExistingSurvey(setQuestions, setSplitWorkshops, setWorkshops, setInitialQuestions, 
                            currentAssessmentServerId, predifinedQuestionIds, languageCode);

    }, [currentAssessmentServerId, predifinedQuestionIds]);


    /**
    * handles press on button reset 
    */
    const handleReset = () => {
        setQuestions(initialQuestions);
    }

    /**
     * Toggles the split workshops feature and resets questions and workshops as needed.
     * 
     * @param {Object} event - The switch event for toggling split workshops.
    */
    const handleSplitWorkshopsChange = (event) => {
        setSplitWorkshops(event.target.checked);

        if (event.target.checked === false) {
            setQuestions(initialQuestions);
            setWorkshops([]);
        }
    };

    /**
     * Prepares the survey data for submission 
     * @param {Array} questions - An array of question objects
     * @returns {Object} An object with a single key, `questions`, containing an array of
     *                   transformed question objects ready for API submission.
     */
    const prepareSurveyData = (questions) => {
        return {
            questions: questions.map((question, index) => ({
                questionId: index,
                shortName: question.shortName,
                context: question.context,
                question: question.question,
                workshop: question.workshop,
                questionType: question.questionType,
                isMandatory: question.isMandatory,
                choices: question.options.map(option => option.label),
            })),
        };
    };

    /**
     * Renders sections for each workshop with its respective questions.
     *
     * @param {Array} questions - Array of questions to be displayed.
     * @param {Object} props - Additional props to pass to the QuestionSection component.
     * @returns {Array} Array of QuestionSection components.
     */
    const renderQuestionSections = (questions, props) => {
        
        return Object.entries(groupQuestionsByWorkshop(questions)).map(([workshopName, workshopQuestions]) => (
            <QuestionsListSection
                key={workshopName}
                workshopName={workshopName}
                questions={workshopQuestions}
                {...props}
            />
        ));
    };

    /**
     * Groups questions by workshop name. Defaults to "default" if not specified.
     *
     * @param {Array} questions - Array of questions to group.
     * @returns {Object} An object with workshop names as keys and arrays of questions as values.
     */
    const groupQuestionsByWorkshop = (questions) => {
        return questions.reduce((acc, question) => {
            const { workshop = "default" } = question; // Fallback to 'default' if workshop is not specified
            if (!acc[workshop]) acc[workshop] = [];
            acc[workshop].push(question);
            return acc;
        }, {});
    };


   return (

    <Box display="flex" flexDirection="column" alignItems="center" minHeight="100vh" ml="10px" bgcolor="white">
        <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="5vh" bgcolor="#fff" width={{ xs: "90vw", md: "75vw" }}>
            <Typography ml="10px" mb="20px" variant="h3" fontWeight="bold">
                {getMessage("label_evaluate")} {assessmentType}
            </Typography>
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="80vh" boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)" borderRadius="15px" bgcolor="#fff" width={{ xs: "90vw", md: "75vw" }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" minHeight="80vh" sx={{ backgroundColor: "#fff", width: {xs: "90vw", md: "75vw",},}}>
                

                <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "#fff", width: { xs: "45vw", md: "37vw" }, height: '78vh', overflowY: 'auto', }}>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "20px", pl: "20px",}}>
                        <FormControlLabel
                            control={<Switch checked={splitWorkshops} onChange={handleSplitWorkshopsChange} />}
                            label={getMessage("label_create_sections")}
                        /> 
                    </Box>
                    
                    {splitWorkshops && (
                        <Box sx={{ display: "flex", flexDirection: "column", mt: "20px", }} >
                            <Typography mb="20px" variant="h4" fontWeight="bold" textAlign="center">
                                {getMessage("label_add_workshop")}
                            </Typography>
                            <AddWorkshop setQuestions={setQuestions} initialQuestions={initialQuestions} workshops={workshops} setWorkshops={setWorkshops}/>
                        </Box>
                    )}

                    <Box sx={{ display: "flex", flexDirection: "column", mt: "20px", ml: "20px", }} >
                        <Typography mb="20px" variant="h4" fontWeight="bold">
                            {getMessage("label_create_new_question")}
                        </Typography>

                        <AddQuestion 
                            setQuestions={setQuestions} 
                            questions={questions} 
                            assessmentType={assessmentType} 
                            splitWorkshops={splitWorkshops} 
                            workshops={workshops} 
                        />
                    </Box>
                </Box>

                <Box flexDirection="column" display="flex" sx={{ backgroundColor: "#fff", width: {xs: "45vw", md: "37vw",}, height: '78vh', overflowY: 'auto', }}>
                    
                    <Formik
                        initialValues={{}}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                const surveyData = prepareSurveyData(questions);
                                await saveSurveyToAssessment(currentAssessmentServerId, surveyData);
                                navigate('/dashboard');
                            } catch (error) {
                                console.error('Failed to submit the survey:', error);
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                                                
                    {({ setFieldValue, handleSubmit }) => (

                        // All the questions are here -> should work for each type
                        <Form>
                            {renderQuestionSections(questions, {
                                setQuestions,
                                setEditingQuestionId,
                                editingQuestionId,
                                setFieldValue,
                            })}

                            <FormActions handleReset={handleReset} handleSubmit={handleSubmit} questionsExist={questions.length > 0} />
                        </Form>
                    )}
                    </Formik>
                </Box>
            </Box>
        </Box>
    </Box>
    );
};

export default AddSurvey;