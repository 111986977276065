import { Box, Typography } from '@mui/material';
import logo_dtc from "../../assets/logo.svg"; 
import { useMessageService } from '../../services/MessageService';

const EndSurvey = () => {
  const { getMessage } = useMessageService();

  return (
    <>
      <Box
        m="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box>
          <img
            alt=""
            width="100%"
            height="100%"
            src={logo_dtc}
            style={{ cursor: 'pointer', borderRadius: '0%' }}
          />
        </Box>
       
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="50px"
            sx={{
                padding: '20px',
                width: {
                xs: '90vw', 
                md: '50vw',
                },
            }}
            >

            <Typography variant="h3" align="center" style={{ margin: '10px' }}>
                {getMessage('label_message_thanks')}
            </Typography> 
         </Box>
      </Box>
    </>
  );
};

export default EndSurvey;
