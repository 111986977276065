import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useMessageService } from '../../services/MessageService';
import { BACKEND_URL } from "../../config";
import { useAuthUser } from '../../contexts/AuthUserContext';
import axios from "axios";
import packageJson from '../../../package.json';
import { veryLightGray } from '../../components/styledComponents';
import { useNavigate } from "react-router-dom";

const Settings = () => {
    const { getMessage } = useMessageService();
    const { currentUser } = useAuthUser();
    const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
    const navigate = useNavigate();

    // This function is called when the user clicks the delete account button
    const handleClickDeleteAccount = () => {
        setDialogConfirmDeleteOpen(true);
    };

    // This function is called when the user cancels the deletion
    const handleCloseDeleteAccount = () => {
        setDialogConfirmDeleteOpen(false);
    };

    // This function performs the actual deletion of the account
    const handleConfirmDeleteAccount = async () => {
        try {
            const token = localStorage.getItem("token");
    
            // Attempt to delete the user's account
            const response = await axios.delete(`${BACKEND_URL}/users/${currentUser._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                timeout: 5000
            });
    
            // Remove the token from local storage
            localStorage.removeItem("token");
    
            // Redirect to the sign-in page
            navigate("/", { replace: true });
    
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.error('Request timed out. Please try again later.');
            } else {
                console.error("Couldn't delete account", error);
            }
        }
    };

    return (
        <Box display="flex" style={{ height: '100vh', overflow: 'auto', padding: '10px' }}>
            <Sidebar />
            <Box display="flex" flex="1" flexDirection="column">
                <Box mt="10px" ml="10px">
                    <Topbar title={getMessage("label_my_account")} />
                </Box>

                {/* users info */}
                <Box mt="20px" ml="10px" p="20px" border="1px solid #ccc" borderRadius="8px" bgcolor={veryLightGray}>
                    <Typography variant="h6" fontWeight="bold" mb="10px">
                        {getMessage('label_user_info')}
                    </Typography>

                    <Typography variant="body1">{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
                    <Typography variant="body1">{`${currentUser.email}`}</Typography>
                    <Typography variant="body1">{`${getMessage('label_status')}: ${currentUser.userStatus}`}</Typography>
                    <Typography variant="body1">{`userId: ${currentUser._id}`}</Typography>
                    <Typography variant="body1">{`codes redeemed: ${currentUser.sharingCodeRedeemed}`}</Typography>
                </Box>

                 {/* platforms info */}
                <Box mt="20px" ml="10px" p="20px" border="1px solid #ccc" borderRadius="8px" bgcolor={veryLightGray}>

                    <Typography variant="h6" fontWeight="bold" mb="10px">
                        {getMessage('label_platform_info')}
                    </Typography>

                    <Box mb="5px">
                        <Typography variant="body1">{`${getMessage('label_name_platform')}: ${packageJson.name}`}</Typography>
                    </Box>

                    <Box>
                        <Typography variant="body1">{`${getMessage('label_version_platform')}: ${packageJson.version}`}</Typography>
                    </Box>
                </Box>

                {/* delete account */}
                <Box mt="20px" ml="10px" p="20px" border="1px solid #ccc" borderRadius="8px"bgcolor={veryLightGray} >

                    <Typography variant="h6" fontWeight="bold" mb="10px">
                        {getMessage('label_delete_account')}
                    </Typography>
                    
                    <Button
                        onClick={handleClickDeleteAccount}
                        sx={{backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred', },}}
                    >
                        {getMessage('label_delete_account')}
                    </Button>

                    <Dialog open={dialogConfirmDeleteOpen} onClose={handleCloseDeleteAccount}>
                        <DialogTitle>{getMessage('label_delete_account_question')}</DialogTitle>
                        <DialogContent>
                             <DialogContentText>
                                {getMessage('label_delete_account_consequences')}
                            </DialogContentText>
                        </DialogContent>
                        
                         <DialogActions>
                             <Button onClick={handleCloseDeleteAccount} color="primary">
                                {getMessage('label_cancel')}
                            </Button>
                            <Button 
                                onClick={handleConfirmDeleteAccount} // Call the delete function here
                                sx={{backgroundColor: 'red', color: 'white', '&:hover': {backgroundColor: 'darkred',},}}
                            >
                                {getMessage('label_delete')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
        </Box>
    );
};

export default Settings;