import React, { useState } from 'react';
import { Box, TextField, Button, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, IconButton } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import Alert from "@mui/material/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import * as Yup from "yup";

// components
import CookieConsentBanner from '../../components/CookieConsentBanner';
import DTCLogo from '../../components/DTCLogo';
import { useMessageService } from '../../services/MessageService';
import LanguageSelector from '../../components/LanguageSelector';
import { buttonStyle, authentificationFormStyle } from '../../components/styledComponents'
import { UserType } from '../../utils/enums';


const Signup = () => {
  
  const [message, setMessage] = useState(null);
  const [cookieConsent, setCookieConsent] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isSandboxMode = location.state?.sandbox;

  const { getMessage } = useMessageService();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    sandbox: isSandboxMode,
    platformMode: "",
    userStatus: ""
  });

  console.log("isSandboxMode",isSandboxMode)

  const createSignupSchema = (platformMode) => {

  let schema = {
    firstName: Yup.string().required(getMessage('label_firstname_required')),
    lastName: Yup.string().required(getMessage('label_lastname_required')),
    email: Yup.string().email('Invalid email').required(getMessage('label_email_required')),
    password: Yup.string().min(6, getMessage('label_minimum_password_characters_required')).required(getMessage('label_password_required')),
    platformMode: Yup.string().required(getMessage('label_platform_mode_required')),
  };

  if (platformMode === 'Education') {
    schema.userStatus = Yup.string().required(getMessage('label_status_required'));
  }

  return Yup.object().shape(schema);
  };

  const handleChange = (event) => {
  const { name, value } = event.target;
  setData(prevData => {
    if (name === "platformMode" && value === "Enterprise") {
      return {
        ...prevData,
        [name]: value,
        userStatus: "Trainer"
      };
    }
    else if (name === "platformMode" && value === "Education") {
      return {
        ...prevData,
        [name]: value,
        userStatus: prevData.userStatus === "Trainer" ? "" : prevData.userStatus
      };
    }
    else {
      return {
        ...prevData,
        [name]: value
      };
    }
  });
};

  const handleAcceptCookies = () => {
  setCookieConsent(true);
  }; 

const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    // Create the schema based on the current platformMode
    const signupSchema = createSignupSchema(data.platformMode);

    // Validate form data against the schema
    await signupSchema.validate(data, { abortEarly: false });

    // API call
    const res = await axios.post(`${BACKEND_URL}/register`, data);
    
    // Reset error message and set success message
    setError("");
    setMessage({
      type: "success",
      text: getMessage("signup_success")
    });
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      setError(error.errors.join(", "));
    } else {
      setError(getMessage("signup_error"));
    }
  }
};

  return (
    <>
    { !cookieConsent && (
        <Box
          position="fixed"
          top={0}
          left={0}
          height="100vh"
          bgcolor="rgba(0,0,0,0.5)" // Semi-transparent black background
        />
    )}
    <CookieConsentBanner onAccept={handleAcceptCookies} language={"fr"}/>   
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        justifyContent="center"
        sx={authentificationFormStyle}
        component="form"
        onSubmit={handleSubmit}
      >


        <Box flexGrow={2} flexBasis={0} padding="60px">

          <LanguageSelector/>
          <DTCLogo/>
          
          <Typography variant="h2" mb={2}>
            {getMessage('signup_create_account')}
          </Typography>

          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            id="firstName"
            label={getMessage('label_first_name')}
            name="firstName"
            autoComplete="firstName"
            autoFocus
            value={data.firstName}
            onChange={handleChange}
          />

          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastName"
            label={getMessage('label_last_name')}
            name="lastName"
            autoComplete="lastName"
            autoFocus
            value={data.lastName}
            onChange={handleChange}
          />

          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={getMessage('label_email')}
            name="email"
            autoComplete="email"
            autoFocus
            value={data.email}
            onChange={handleChange}
          />
          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label={getMessage('label_password')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={data.password}
            onChange={handleChange}
          />

          <FormControl component="fieldset" margin="normal" sx={{ display: 'flex', alignItems: 'center' }}>
          <FormLabel component="legend" sx={{ mr: 1 }}>
            {getMessage('signup_platform_mode')}
            <Tooltip 
              title={getMessage('signup_platform_mode_note')}
              arrow
            >
              <IconButton size="small">
                <HelpIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </FormLabel>
          <RadioGroup
            row
            name="platformMode"
            value={data.platformMode}
            onChange={handleChange}
          >
            <FormControlLabel value="Education" control={<Radio />} label={getMessage('signup_education')} />
            <FormControlLabel value="Enterprise" control={<Radio />} label={getMessage('signup_enterprise')}  disabled={true} />
          </RadioGroup>
        </FormControl>

        {data.platformMode === "Education" && (
          <FormControl component="fieldset" margin="normal" sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel component="legend" sx={{ mr: 1 }}>
              {getMessage('label_status')}
            </FormLabel>
            <RadioGroup
              row
              name="userStatus"
              value={data.userStatus}
              onChange={handleChange}
            >
              <FormControlLabel value={UserType.TEACHER_TRAINER} control={<Radio />} label={getMessage('signup_teacher_trainer')} />
              <FormControlLabel value={UserType.TEACHER} control={<Radio />} label={getMessage('signup_teacher')} />
            </RadioGroup>
          </FormControl>
        )}

          <Box mt="10px" mb="10px">
            {error ? (
                <Alert severity="error">{error}</Alert>
            ) : (
                message && <Alert severity="success">{message ? message.text : ""}</Alert>
            )}
          </Box>

          <Box mt={5} display="flex" justifyContent="center">
            <Button disabled={!cookieConsent} type="submit" variant="contained" sx={{...buttonStyle, width: '30%'}}>
              <Typography variant="h5">{getMessage('label_next')}</Typography>
            </Button>
          </Box>


          <Box mt={5} display="inline-flex" alignItems="center">
            <Typography>
              {getMessage('label_has_account_already')}
            </Typography>

            <Typography 
              style={{ textDecoration: "underline", cursor: "pointer", marginLeft: '5px' }} 
              onClick={() => navigate("/signin")}
              color="primary"
            >
              {getMessage('label_signin')}
            </Typography>
          </Box>

        </Box>
      </Box>
    </Box>
  </Box>
</>
  );
};

export default Signup;
