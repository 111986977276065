import { Box, Typography, FormControlLabel, Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import React, { useState, useEffect} from "react";
import axios from "axios";

import { BACKEND_URL } from "../config";
import AddLearningQuestions from "./AddLearningQuestions";
import FormActions from './FormActions';
import AddWorkshop from "./AddWorkshop";
import QuestionsListSectionLearning from './QuestionSectionLearning';
import { digCompEduElementaryCompetences, digCompEduActivities, RCNumObligatoireElementaryCompetences,
         RCNumObligatoireActivities, RCNumPostObligatoireElementaryCompetences, RCNumPostObligatoireActivities } from "../assets/frameworksData";
import { saveSurveyToAssessment, fetchExistingSurvey } from '../utils/SurveyUtils';
import { useLanguage } from '../contexts/LanguageContext';

// for i18n
import { useMessageService } from '../services/MessageService';

const AddLearning = ({ currentAssessmentServerId, predifinedQuestionIds }) => {
    
    const location = useLocation();
    const navigate = useNavigate();

    // States declaration
    const { assessmentType } = location.state || {};
    const [initialQuestions, setInitialQuestions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [splitWorkshops, setSplitWorkshops] = useState(false);
    const [editingQuestionId, setEditingQuestionId] = useState(null);
    const [automaticEncoding, setAutomaticEncoding] = useState(false);
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCompetency, setSelectedCompetency] = useState('');
    const [activity, setActivity] = useState(''); 

    // for the translations
    const { getMessage } = useMessageService();
    const { languageCode } = useLanguage();

    useEffect(() => {

        fetchExistingSurvey(setQuestions, setSplitWorkshops, setWorkshops, setInitialQuestions, currentAssessmentServerId, predifinedQuestionIds, languageCode);

    }, [currentAssessmentServerId, predifinedQuestionIds]);


    /**
     * Handles changes to competency-related selections and updates the corresponding state.
     * This function manages different select elements for areas, competencies, and activities.
     * Based on the name attribute of the select element, it updates the state accordingly.
     * For the 'activity' selection, it also adds the selected activity to the competencies of the current question.
     *
     * @param {Object} event - The event object from the select element, containing 'name' and 'value' properties.
     */
    const handleCompetencyChange = (event) => {
        const { name, value } = event.target;
        if (name === 'area') {
            setSelectedArea(value);
        } else if (name === 'competency') {
            setSelectedCompetency(value);
        } else if (name === 'activity') {
            setActivity(value);
            const updatedCompetencies = [...questions.find(question => question.questionId === editingQuestionId).competencies, value];
            setQuestions(questions.map(question => question.questionId === editingQuestionId ? { ...question, competencies: updatedCompetencies } : question));
        }
    };

    /**
     * Retrieves competency arrays based on the selected educational framework and area.
     * This function helps to provide specific competencies depending on the educational context.
     * 
     * @param {Object} values - An object containing the framework and area selected.
     * @param {string} values.framework - The educational framework identifier.
     * @param {string} values.area - The area within the framework for which competencies are sought.
     * @returns {Array} - An array of competencies related to the specified area within the framework.
    */
    function getCompetencies(values) {
        switch (values.framework) {
        case "RCNUM POST-OBLIGATOIRE":
            return RCNumPostObligatoireElementaryCompetences[values.area] || [];
        case "RCNUM OBLIGATOIRE":
            return RCNumObligatoireElementaryCompetences[values.area] || [];
        case "DIGCOMPEDU":
            return digCompEduElementaryCompetences[values.area] || [];
        default:
            return digCompEduElementaryCompetences[values.area] || [];
        }
    }

    /**
     * Fetches activities related to a specific competency within a given educational framework.
     * This function provides activities that are aligned with the selected competency to guide educational efforts.
     * 
     * @param {Object} values - An object containing the framework and competency.
     * @param {string} values.framework - The educational framework identifier.
     * @param {string} values.competency - The competency for which activities are sought.
     * @returns {Array} - An array of activities related to the specified competency within the framework.
    */
    function getActivities(values) {
        switch (values.framework) {
            case "RCNUM POST-OBLIGATOIRE":
                return RCNumPostObligatoireActivities[values.competency] || [];
            case "RCNUM OBLIGATOIRE":
                return RCNumObligatoireActivities[values.competency] || [];
            case "DIGCOMPEDU":
                return digCompEduActivities[values.competency] || [];
            default:
                return digCompEduActivities[values.competency] || [];
        }
    };
    
    /**
     * Performs a backend query to fetch competencies related to a given query within a specified framework using FAISS.
     * This function uses vector search to find relevant competencies, making it suitable for educational planning and AI integrations.
     * 
     * @param {string} query - The search query string used to find related competencies.
     * @param {string} framework - The educational framework where the query will be applied.
     * @returns {Promise<Object>} - A promise that resolves to the data containing the search results, or null if the framework is not specified.
    */
    const findCompetencies = async (query, framework) => {
        console.log("framework", framework)
        try {
            if (framework) { 
                const token = localStorage.getItem("token");
                const response = await axios.post(
                `${BACKEND_URL}/query-embedding-faiss`,
                { query, collectionName: framework },
                {
                    headers: {
                    Authorization: `Bearer ${token}`
                    }
                }
                );
            return response.data;
        } else {
            console.log("Framework is not specified. Skipping the request.");
            return null; 
        }
        } catch (error) {
            console.error('Error sending query to backend:', error);
        }
    };


    const handleAutomaticEncodingChange = async (event) => {
        const isAutomaticEncodingEnabled = event.target.checked;
        setAutomaticEncoding(isAutomaticEncodingEnabled);

        // Only proceed if automatic encoding is enabled and a question is currently being edited
        if (isAutomaticEncodingEnabled && editingQuestionId !== null) {
            const updatedQuestion = questions.find(question => question.questionId === editingQuestionId);
            
            console.log("udpatedQuestion", updatedQuestion)

            if (updatedQuestion) {
                try {
                    // Simulate the logic from saveEdits for updating competencies
                    const competencies = await findCompetencies(updatedQuestion.question, updatedQuestion.framework);
                    if (competencies && competencies.length > 0) {
                        const updatedCompetencies = competencies.map(comp => `${updatedQuestion.framework} ${comp}`);
                        // Update the question with the new competencies
                        setQuestions(prevQuestions => prevQuestions.map(question =>
                            question.questionId === editingQuestionId ? { ...question, competencies: updatedCompetencies } : question
                        ));
                        console.log("Competencies automatically updated for question:", editingQuestionId);
                    } else {
                        console.log("No competencies found for automatic recoding.");
                    }
                } catch (error) {
                    console.error("Failed to automatically update competencies for question:", editingQuestionId, error);
                }
            }
        }
    };


    // Event handler for changing the state of helpWithAI
    const handleSplitWorkshopsChange = (event) => {
        setSplitWorkshops(event.target.checked);

        if (event.target.checked === false) {
            setQuestions(prevQuestions => {
                return prevQuestions.map(question => {
                    return { ...question, workshop: "" };
                });
            });
            setWorkshops([]);
        }
    };

    const handleReset = () => {
        setQuestions([]);
        setWorkshops([]);
        setSplitWorkshops(false);
    };

    /**
     * Renders sections for each workshop with its respective questions.
     *
     * @param {Array} questions - Array of questions to be displayed.
     * @param {Object} props - Additional props to pass to the QuestionSection component.
     * @returns {Array} Array of QuestionSection components.
     */
    const renderQuestionSections = (questions, props) => {
        
        return Object.entries(groupQuestionsByWorkshop(questions)).map(([workshopName, workshopQuestions]) => (
            <QuestionsListSectionLearning
                key={workshopName}
                workshopName={workshopName}
                questions={workshopQuestions}
                handleCompetencyChange={handleCompetencyChange}
                handleAutomaticEncodingChange={handleAutomaticEncodingChange}
                selectedArea={selectedArea}
                automaticEncoding={automaticEncoding}
                activity={activity}
                getActivities={getActivities}
                selectedCompetency={selectedCompetency}
                getCompetencies={getCompetencies}
                {...props}
            />
        ));
    };

    /**
     * Groups questions by workshop name. Defaults to "default" if not specified.
     *
     * @param {Array} questions - Array of questions to group.
     * @returns {Object} An object with workshop names as keys and arrays of questions as values.
     */
    const groupQuestionsByWorkshop = (questions) => {
        return questions.reduce((acc, question) => {
            const { workshop = "default" } = question; // Fallback to 'default' if workshop is not specified
            if (!acc[workshop]) acc[workshop] = [];
            acc[workshop].push(question);
            return acc;
        }, {});
    };
 
   return (
            
        <Box display="flex" flexDirection="column" alignItems="center" minHeight="100vh" ml="10px" backgroundColor="white">  
            <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="5vh" sx={{backgroundColor: "#fff", width: {xs: "90vw", md: "75vw",}, }}>
                <Typography ml ="10px" mb="20px" variant="h3" fontWeight="bold">
                    {getMessage("label_evaluate")} {assessmentType}
                </Typography>
            </Box>                                            
            
            <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="80vh" sx={{boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", borderRadius: "15px", backgroundColor: "#fff", width: {xs: "90vw", md: "75vw",},}}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" minHeight="80vh" sx={{backgroundColor: "#fff", width: {xs: "90vw", md: "75vw",},}}>

                <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "#fff", width: { xs: "45vw", md: "37vw" }, height: '78vh', overflowY: 'auto', }}>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "20px", pl: "20px",}}>
                        <FormControlLabel
                            control={<Switch checked={splitWorkshops} onChange={handleSplitWorkshopsChange} />}
                            label={getMessage("label_create_sections")} 
                        /> 
                    </Box>
                
                    {splitWorkshops && (
                        <Box sx={{ display: "flex", flexDirection: "column", mt: "20px", }} >
                            <Typography mb="20px" variant="h4" fontWeight="bold" textAlign="center">
                                {getMessage("label_add_workshop")}
                            </Typography>
                            <AddWorkshop setQuestions={setQuestions} initialQuestions={initialQuestions} workshops={workshops} setWorkshops={setWorkshops}/>
                        </Box>
                    )}

                    <Box sx={{ display: "flex", flexDirection: "column", mt: "20px", ml: "20px", }} >
                        <Typography mb="20px" variant="h4" fontWeight="bold">
                            {getMessage("label_create_new_question")}
                        </Typography>

                        <AddLearningQuestions 
                            setQuestions= {setQuestions}
                            questions={questions} 
                            assessmentType={assessmentType} 
                            splitWorkshops={splitWorkshops} 
                            workshops={workshops} 
                        />
                    </Box>
                </Box>

                <Box flexDirection="column" display="flex" sx={{backgroundColor: "#fff",width: {xs: "45vw", md: "37vw",}, height: '78vh', overflowY: 'auto',}} >
                    
                    <Formik
                        initialValues={{}}
                        onSubmit={async (values, { setSubmitting }) => {
                            const surveyData = {
                            questions: questions.map((question, index) => ({
                                questionId: index,
                                shortName: question.shortName,
                                context: question.context,
                                correctAnswer: question.correctAnswer,
                                explanation: question.explanation,
                                question: question.question,
                                workshop: question.workshop,
                                questionType: question.questionType,
                                learningType: question.learningType,
                                isMandatory: question.isMandatory,
                                framework: question.framework,
                                competencies: question.competencies,
                                choices: question.options.map(option => option.label),
                            })),
                            };
                        
                            try {
                                await saveSurveyToAssessment(currentAssessmentServerId, surveyData);
                                navigate('/dashboard');
                                } catch (error) {
                                console.error('Failed to submit the survey:', error);
                                } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                            
                        {({ setFieldValue, handleSubmit }) => (

                            // All the questions are here -> should work for each type
                            <Form>
                                {renderQuestionSections(questions, {
                                    setQuestions,
                                    setEditingQuestionId,
                                    editingQuestionId,
                                    setFieldValue,
                                })}

                                <FormActions handleReset={handleReset} handleSubmit={handleSubmit} questionsExist={questions.length > 0} />
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Box>
    </Box>);
};

export default AddLearning;



