import {Box, Button, Typography} from "@mui/material";
import logo from "../../assets/logo.svg"; 
import createMonitoringAssessment from "../../assets/create-monitoring-and-assessment.gif"; 
import editPreviewOpenAssessment from "../../assets/edit-preview-open-assessment.gif"; 
import results from "../../assets/results.gif"; 
import logbook from "../../assets/logbook.gif"; 
import logoEpfl from "../../assets/logo-epfl.svg";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useNavigate } from "react-router-dom";
import Topbar from "../../scenes/global/Topbar";
import { useMessageService } from '../../services/MessageService';
import { buttonStyle } from '../../components/styledComponents'

const Home = () => {

  const navigate = useNavigate();
  const { getMessage } = useMessageService();


/**
 * Wave SVG component
 */
  const Wave = ({ reverse }) => (
    <svg viewBox="0 0 1200 120" preserveAspectRatio="none" style={{ width: '100%', height: '150px', transform: reverse ? 'rotate(0deg)' : 'rotate(180deg)' }}>
      <path d="M0 0 C 300 100 650 0 1200 100 V 120 H 0 Z" fill="rgba(87,193,202,0.5)"></path>
    </svg>
);

  return (
    <Box display="flex" backgroundColor="white" style={{height: '100vh', overflow: 'auto'} }>
      <Box>
        <Topbar logo={logo} boxShadow={"rgba(0, 0, 0, 0.24) 0px 8px 8px -8px"}/>

        <Box>

          <Wave/>

          <Box display="flex" flexDirection="column" alignItems="center" mt={-5} ml={20} mr={20}>

            <Typography variant="h2" sx={{ fontWeight: "bold" }} mb={2} >
              {getMessage('dtc_description')}
            </Typography>
            <Typography variant="h3" align="justify" mt={1} mb={5} >
              {getMessage('dtc_description_2')}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
              <Button variant="contained" onClick={() => navigate("/signup")} sx={buttonStyle}>
                <Typography variant="h4">{getMessage('label_signup')}</Typography>
              </Button>
              <Button variant="contained" onClick={() => navigate("/dashboard")} sx={buttonStyle}>
                <Typography variant="h4">{getMessage('label_signin')}</Typography>
              </Button>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1 }}>
              <Typography variant="h6">{getMessage('label_platform_is_beta')}</Typography>
              <Typography variant="h6">{getMessage('label_trial_version')}</Typography>
              <Typography variant="h6">
                {getMessage('label_report_bugs')}
                <a href="mailto:info@digitaltrainingcompanion.tech">info@digitaltrainingcompanion.tech</a>
              </Typography>
            </Box>
            
            
            <Typography variant="h3" align="justify" mt={4} >
              {getMessage('label_survey_description')}
            </Typography>

            <Box mt={3} display="flex" justifyContent="center">
              <Button variant="contained" onClick={() => navigate("/survey")} sx={buttonStyle} >
                 <Typography variant="h4">{getMessage('label_survey_participate')}</Typography>
                </Button>
            </Box>

            <Box mt={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Typography variant="h6">{getMessage('label_survey_time')}</Typography>
              <Typography variant="h6">{getMessage('label_survey_view_results')}</Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
              <img
              alt=""
              src={logoEpfl}
              style={{
                  cursor: "pointer", 
                borderRadius: "1%", 
                maxWidth: "200px",
                minWidth: "200px",
              }}
              />
            </Box>
          </Box>
        </Box>


        <Wave reverse={false}/>

        <Box display="flex" flexDirection="column" alignItems="center" ml={20} mr={20} mb={2}>
            <Typography variant="h2" sx={{ fontWeight: "bold" }} mt={8} align="center" >
              {getMessage('dtc_description_3')}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
              {getMessage('dtc_description_4')}
            </Typography>
            <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
              {getMessage('dtc_description_5')}
            </Typography>


            <img alt=""  src={createMonitoringAssessment} style={{cursor: "pointer", borderRadius: "1%", maxWidth: "90%", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"}}/>

            <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
              {getMessage('dtc_description_6')}
            </Typography>
            <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
              {getMessage('dtc_description_7')} 
            </Typography>
                
            <img alt="" src={editPreviewOpenAssessment}  style={{cursor: "pointer", borderRadius: "1%", maxWidth: "90%", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"}}/>
            
            <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
              {getMessage('dtc_description_8')}
            </Typography>
            <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
              {getMessage('dtc_description_9')}
            </Typography>
                
            <img  alt="" src={results} style={{cursor: "pointer", borderRadius: "1%", maxWidth: "90%", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"}}/>
            
            <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
              {getMessage('dtc_description_10')}  
            </Typography>
            <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
              {getMessage('dtc_description_11')}
            </Typography>    

            <img alt="" src={logbook} style={{cursor: "pointer", borderRadius: "1%", maxWidth: "90%", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"}}/>
                
          </Box>
        <Box>  
      </Box>
    </Box>
  </Box>)
};



export default Home;
