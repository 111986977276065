import React from 'react';
import { Delete, ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from '@mui/material';
import { reorderQuestionWithinWorkshop, removeQuestion } from '../utils/SurveyUtils';

// for i18n
import { useMessageService } from '../services/MessageService';

// the view with all the elements for the control - edit, delete, up, down, ect...
const ContextControlsView = ({ questions, setQuestions, setEditingQuestionId, question }) => {

    // for the translations
    const { getMessage } = useMessageService();

    return (
        <Box display="flex" flexDirection="column">

            <Tooltip title={getMessage("label_tooltip_remove_question")}>
                <IconButton onClick={() => removeQuestion(setQuestions, question.workshop, question.questionId)} sx={{ color: 'red' }}>
                    <Delete />
                </IconButton>
            </Tooltip>
            
            <Tooltip title={getMessage("label_tooltip_move_question_up")}>
                <IconButton onClick={() => reorderQuestionWithinWorkshop(questions, setQuestions, question.workshop, question.questionId, 'up')} >
                    <ArrowUpward />
                </IconButton>
            </Tooltip>
            
            <Tooltip title={getMessage("label_tooltip_move_question_down")}>
                <IconButton onClick={() => reorderQuestionWithinWorkshop(questions, setQuestions, question.workshop, question.questionId, 'down')} >
                    <ArrowDownward />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ContextControlsView;